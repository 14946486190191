import React from "react";
import { Card, Table } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import avatarimg1 from "../assets/img/userport.png";
import { AuthgetApi } from "../axiosConfig/promise";
import { useQuery } from "@tanstack/react-query";
import { Image_endpoint } from "../../local";
export default function Viewuser() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  let url = "admingetUser?userId="+id
  const { data:userinfo, error, isLoading } = useQuery({
    queryKey: ['userListing', url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });
  // const {data = userinfo
  console.log(userinfo,"userdetails")
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>User Details</h5>
          <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140" to="/Users">Back</Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Table responsive>
            <tbody>
              <tr>
                <td><b>Name</b></td>
                <td>{userinfo?.data?.fullName}</td>
              </tr>
              <tr>
                <td><b>Email</b></td>
                <td>{userinfo?.data?.email}</td>
              </tr>
              <tr>
                <td><b>Phone No.</b></td>
                <td>{userinfo?.data?.phoneNumber}</td>
              </tr>
              {/* <tr>
                <td><b>Status</b></td>
                <td><span className={userinfo?.data?.status + ' badge' }  >{userinfo?.data?.status}</span></td>
              </tr> */}
              <tr>
                <td><b>Image</b></td>
                <td>
                <img className="dtlimg img-thumbnail" src={userinfo?.data?.profilePic==null?avatarimg1: Image_endpoint+userinfo?.data?.profilePic} /></td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}