import { AuthgetApi, NonAuthPostAPi, authFormdataPut } from "./promise";
import Cookies from 'js-cookie'; // Import Cookies from js-cookie package

// api.js
export const loginUser = async ({ url, email, password }) => {
  try {
    // Validate email and password
    if (!email || !password) {
      throw new Error("Email and password are required");
    }

    // Make API call to authenticate user
    const response = await NonAuthPostAPi(url, { email, password });

    // Check for errors in the response
    if (!response || response.error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  

    // Return the response data
    return response;
  } catch (error) {
    // Handle errors
    console.error("Login error:", error.message);
    throw error; // Rethrow the error to propagate it to the caller
  }
};

export const getadminDetails = async (url) => {
    try {
        // Make API call to authenticate user
        let response = await AuthgetApi(url);
        // Check for errors in the response
        if (!response || response.error) {
            throw new Error(response.error || "Failed to fetch user details. Please try again.");
        }

        let data =  response.data;
        // Return the response data
        return data
    } catch (error) {
        console.error("Error getting user details:", error.message);

        if (error.response && error.response.data && error.response.data.message) {
            let message = error.response.data.message;
            if (message === "Invalid token.") {
                Cookies.remove('token')
                window.location.href = "/";
            }
        }

        throw error; // Rethrow the error to propagate it to the caller
        return 
    }
};

export const adminupdate = async (url,formdata) => {
  try {
      // Make API call to authenticate user
      let response = await authFormdataPut(url,formdata);
      // Check for errors in the response
      if (!response || response.error) {
          throw new Error(response.error || "Failed to fetch user details. Please try again.");
      }

      let data =  response.data;
      // Return the response data
      return data
  } catch (error) {
      console.error("Error getting user details:", error.message);

      if (error.response && error.response.data && error.response.data.message) {
          let message = error.response.data.message;
          if (message === "Invalid token.") {
              Cookies.remove('token')
              window.location.href = "/";
          }
      }

      throw error; // Rethrow the error to propagate it to the caller
      return 
  }
};

export const getuserlisting = async (url,role) => {
  try {
      // Make API call to authenticate user
      let response = await AuthgetApi(url+"?role="+role);
      // Check for errors in the response
      if (!response || response.error) {
          throw new Error(response.error || "Failed to fetch user listing. Please try again.");
      }

      let data =  response.data;
      // Return the response data
      return data
  } catch (error) {
      console.error("Error getting user details:", error.message);

      if (error.response && error.response.data && error.response.data.message) {
          let message = error.response.data.message;
          if (message === "Invalid token.") {
              Cookies.remove('token')
              window.location.href = "/";
          }
      }

      throw error; // Rethrow the error to propagate it to the caller
      return 
  }
};