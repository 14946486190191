import React, { useState } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Avatar from "../assets/img/userport.png";
import validationSchema from "../formValidation/addUserSchema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authFormdataPost } from "../axiosConfig/promise";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { ThreeCircles } from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Adduser() {
  const [form, setform] = useState({});
  const [errors, setErrors] = useState({});
  const [previewImage, setpreview] = useState(null);
  const [sendingImage, setImage] = useState(null);
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const convertToFormData = (form) => {
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    return formData;
  };
  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      // Store the token and fetch user details
      queryClient.invalidateQueries("userListing"); // Invalidate and refetch admin data if you have a query for it

      console.log(data, "is here --- >");
      if (data.status) {
        navigate(-1);
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error("Login error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "userListing", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const validate = () => {
    const { error } = validationSchema.validate(form, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleImage = (event) => {
    let file = event.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setpreview(previewURL);
      setImage(file);
    }
    return;
  };
  const handleSubmit = (e) => {
    console.log("woring");
    e.preventDefault();
    const formErrors = validate();
    console.log(formErrors, "formErrors");
    setErrors(formErrors);
    let formdata = new FormData();
    form.profilePic = sendingImage;
    if (Object.keys(formErrors).length === 0) {
      let parms = {
        url: "adminAdduser",
        formdata: convertToFormData(form),
      };
      mutation.mutate(parms);
      // Submit the form
      // console.log('Form submitted successfully', values);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add User</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Users"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 Col">
              <div className="user_info text-center d-flex flex-column item-center">
                <img
                  src={previewImage ?? Avatar}
                  className="rounded-circle img-fluid profilepic"
                  alt=""
                />
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="uploadprofileimg"
                  onChange={handleImage}
                  className="d-none"
                />
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={form?.fullName}
                        onChange={(e) =>
                          setform({ ...form, fullName: e.target.value })
                        }
                      />
                      <p style={{ fontSize: 12, color: "red" }}>
                        {errors.fullName && <span>{errors.fullName}</span>}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={form?.email}
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                      />
                      <p style={{ fontSize: 12, color: "red" }}>
                        {errors.email && <span>{errors.email}</span>}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone no."
                        value={form?.phoneNumber}
                        onChange={(e) =>
                          setform({ ...form, phoneNumber: e.target.value })
                        }
                      />
                      <p style={{ fontSize: 12, color: "red" }}>
                        {errors.phoneNumber && (
                          <span>{errors.phoneNumber}</span>
                        )}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3" style={{ position: "relative" , height:"100px"}}>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password."
                        value={form?.password}
                        onChange={(e) =>
                          setform({ ...form, password: e.target.value })
                        }
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                      <p style={{ fontSize: 12, color: "red" }}>
                        {errors.password && <span>{errors.password}</span>}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  onClick={handleSubmit}
                >
                  {" "}
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Toaster />
    </React.Fragment>
  );
}
