import React, { useState } from "react";
import {
    Card,
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import advertisementimg1 from '../assets/img/advertisementimg1.jpg'
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthPost, AuthgetApi } from "../axiosConfig/promise";
import { Image_endpoint } from "../../local";
import toast, { Toaster } from "react-hot-toast";
export default function Advertisement() {
    const [searchText, setSearchText] = useState('');
    const queryClient = useQueryClient();

    const url = `adminGetaddvertisementList`;

    const { data: advlist, error, isLoading } = useQuery({
      queryKey: ['advlist', url],
      queryFn: () => AuthgetApi(url),
      staleTime: Infinity,
    });

    function downloadImage(imageUrl) {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = imageUrl;
        
        // Extract the image file name from the URL
        const fileName = imageUrl.split('/').pop();
        link.download = fileName;
        link.target = '_blank';

        // Append the anchor to the body
        document.body.appendChild(link);
        
        // Programmatically trigger a click event on the anchor
        link.click();
        
        // Remove the anchor from the body
        document.body.removeChild(link);
    }
    
  
    const columns = [
        {
          name: 'Image',
          selector: row => row.imgurl,
          sortable: true,
          cell: (row) => 
          <>
            <img className="table_img rounded-circle img-thumbnail me-2" src={row.bannerImg==null?advertisementimg1:Image_endpoint+row.bannerImg} />
          </>      
        },
        {
            name: 'Business',
            selector: row => row.businessId?.businessName, 
            sortable: true,
        },
        // {
        //     name: 'Date',
        //     selector: row => row.plan, 
        //     sortable: true,
        // },
        {
            name: 'Offer',
            selector: row => row.message, 
            sortable: true,
        },
        {
            name: 'Actions',
            minWidth: '150px',
            cell: row => (
                <>
                    <Link className="btnedit" onClick={()=>downloadImage(Image_endpoint+row.bannerImg)}>
                        <Icon icon="material-symbols:download"></Icon>
                    </Link>
                    <Link className="btnview" onClick={(e)=>handleEditSubmit(e,row?._id,"approved")} >
                        <Icon icon="mdi:check"></Icon>
                    </Link>
                    <Link className="btndelete" onClick={(e)=>handleEditSubmit(e,row?._id,"rejected")}>
                        <Icon icon="mdi:close"></Icon>
                    </Link>
                </>
            ),
        },
    ];

    const Updatemutation = useMutation({
        // mutationFn: (params) => AuthPost(params.url, { subCategory: params.subCategory, subCategoryId: params.categoryId}),
        mutationFn: (params) => AuthPost(params.url),
        onSuccess: (data) => {
            if (data.status) {
                toast.success('Status updated successfully!');
                queryClient.invalidateQueries('subcatglisting');
                // setErrors({});
                // handleClose();
                // handleClose2();
                return;
            }
            toast.error(data?.message);
        },
        onError: (error) => {
            console.error('Error:', error.message);
            toast.error(error.message);
        },
        retry: 3,
        retryDelay: 1000,
        mutationKey: 'advlist',
        onErrorThrow: true,
    });


    const handleEditSubmit = (e,id,status) => {
        e.preventDefault();
            const params = {
                url: `updateBannerStatus?bannerId=${id}&status=${status}`,
            };
            Updatemutation.mutate(params);
    };

    const data = [
        { 
            id: 1, 
            imgurl: advertisementimg1,
            business: 'Hard Rock Cafe',  
            plan: '7 Days',  
            price: '$20',  
        },
        { 
            id: 2, 
            imgurl: advertisementimg1,
            business: 'Burrito',  
            plan: '14 Days',  
            price: '$35',  
        },
        { 
            id: 3, 
            imgurl: advertisementimg1,
            business: 'Hard Rock Cafe',  
            plan: '21 Days',  
            price: '$50',  
        },
    ]
    const filteredData =advlist?.data?.list?.filter(
        item =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
      );
    return (
        <React.Fragment>
            <Card>
                <Card.Header className="py-4">
                    <h5>Advertisement</h5>
                </Card.Header>
                <Card.Body>
                    <div className="text-end mb-3">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="searchfield"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        responsive
                        className="custom-table"
                    />
                </Card.Body>
            </Card>
            <Toaster />
        </React.Fragment>
    );
}